import styles from "./EmptyState.module.scss";
interface PageProps {
    code?: number;
    img?: any;
    title?: string;
    subtitle?: string;
}

export default function EmptyState(props: PageProps) {
    return (
        <div className={styles.empty_state}>
            <h1 className={styles.code}>{props?.code}</h1>
            <h2 className={styles.title}>{props?.title}</h2>
            <p className={styles.subtitle}>{props?.subtitle}</p>
        </div>
    )
}