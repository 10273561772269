import './App.css'
import Page from './components/page/Page'
import Sidebar from './components/sidebar/Sidebar'
import styles from './App.module.scss'
import { useAuth } from './providers/AuthContext'
import { Loading } from 'react-daisyui'

interface PageProps {
	children?: React.JSX.Element | React.JSX.Element[] | string | string[] | null | undefined
}
function App(props: PageProps) {
	const { loading } = useAuth()

	return loading ?
		<div className={styles.loading}>
			<Loading size='lg' color='primary' />
		</div> :
		<div className={styles.app}>
			<Sidebar />
			<Page>
				{props.children}
			</Page>
		</div>
}

export default App
