import { useEffect, useState } from "react";
import { Empresa } from "./interface";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import GaugeComponent from "react-gauge-component";
import { Button, Loading } from "react-daisyui";
import styles from "./ResultadoEmpresa.module.scss";

export default function ResultadoEmpresa() {
    const { empresaId } = useParams();
    const [empresa, setEmpresa] = useState<Empresa | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [resultado_empresa, setResultadoEmpresa] = useState<number>(0);
    const [cantidad_encuestas, setCantidadEncuestas] = useState<number>(0);
    const [arcos, setArcos] = useState({});
    const navigate = useNavigate();

    const descargarInfoEmpresa = () => {
        if (empresaId) {
            axios.get(`/api/empresas/${empresaId}/resultados`)
                .then((response) => {
                    setEmpresa(response.data.empresa);
                    setResultadoEmpresa(response.data.resultado_empresa);
                    setCantidadEncuestas(response.data.cantidad_encuestas);
                })
                .catch((error) => {
                    console.error("Error al cargar los datos de la empresa:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        descargarInfoEmpresa();
        setArcos({
            subArcs: [
                {
                    limit: 50,
                    color: '#EA4228',
                    showTick: true
                },
                {
                    limit: 85,
                    color: '#F5CD19',
                    showTick: true
                },
                {
                    limit: 100,
                    color: '#5BE12C',
                    showTick: true
                },
            ]
        });
    }, [empresaId]);

    return (
        <div className={styles.resultados_wrapper}>
            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <Loading size="lg" color="primary" />
                </div>
            ) : (
                <div className={styles.resultados}>
                    <div className={styles.actions}>
                        <Button onClick={() => navigate(-1)} color="ghost" size='sm'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z" /></svg>
                            Volver
                        </Button>
                    </div>

                    <div className={styles.data}>
                        <GaugeComponent arc={arcos} value={resultado_empresa} style={{ width: '500px', margin: '0 auto', paddingTop: '2rem' }} />
                        <h3 className="text-2xl font-bold mb-4">{empresa?.nombre}</h3>
                        <p>Cantidad de encuestas: <b>{cantidad_encuestas}</b></p>
                    </div>
                </div>
            )}
        </div>
    )
}