import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Cuestionario, Tema } from "../interface";
import { Badge, Button, Checkbox, Input, Loading, Modal, Pagination, Rating, Select, Table, Textarea, Tooltip } from "react-daisyui";
import axios from "axios";
import Swal from "sweetalert2";
import { useDebouncedCallback } from "use-debounce";
import styles from "./Temas.module.scss";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

type TemasSchemaType = Yup.InferType<typeof temasSchema>;

const temasSchema = Yup.object().shape({
    nombre: Yup.string()
        .required('El campo es obligatorio')
        .min(2, 'Mínimo 2 caracteres'),
    nombre_mostrable: Yup.string()
        .required('El campo es obligatorio')
        .min(2, 'Mínimo 2 caracteres'),
    detalle: Yup.string()
        .max(1000, 'Máximo 1000 caracteres'),
});

export default function Temas() {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const { cuestionarioId } = useParams()
    const [cuestionario, setCuestionario] = useState<Cuestionario | null>(null);
    const [tema, setTema] = useState<Tema | null>(null);
    const [temas, setTemas] = useState<Array<Tema>>([]);

    const [nombre, setNombre] = useState<string>('');
    const [nombre_mostrable, setNombreMostrable] = useState<string>('');
    const [detalle, setDetalle] = useState<string>('');
    const [informativo, setInformativo] = useState<boolean>(false);
    const [tema_activo, setTemaActivo] = useState<boolean>(true);
    const [peso, setPeso] = useState<number>(0);
    const [orden, setOrden] = useState<number>(1);

    const [search, setSearch] = useState<string>('');
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)

    const detailModal = useRef<HTMLDialogElement>(null);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<TemasSchemaType>({
        resolver: yupResolver(temasSchema)
    });

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;
        const totalPages = Math.ceil(total / limit);
        const maxPage = Math.min(totalPages, maxPageButtons);
        const middlePage = Math.ceil(maxPage / 2);
        const startPage = Math.max(1, page - middlePage);
        const endPage = Math.min(totalPages, startPage + maxPage - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
                    {i}
                </Button>
            );
        }

        return pageNumbers;
    }

    const descargarInfoCuestionario = () => {
        if (cuestionarioId) {
            axios.get(`/api/cuestionarios/${cuestionarioId}`)
                .then((response) => {
                    setCuestionario(response.data.cuestionario);
                })
                .catch((error) => {
                    console.error("Error al cargar los datos del cuestionario:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const descargarTemas = () => {
        if (cuestionarioId) {
            axios.get(`/api/cuestionarios/${cuestionarioId}/temas`)
                .then((response) => {
                    setTemas(response.data.data);
                    setTotal(response.data.total);
                })
                .catch((error) => {
                    console.error("Error al cargar los temas del cuestionario:", error);
                });
        }
    }

    const guardarTema = () => {
        axios(`/api/cuestionarios/${cuestionarioId}/temas${tema && tema.id ? `/${tema.id}` : ''}`, {
            method: tema && tema.id ? 'PUT' : 'POST',
            data: {
                ...tema,
                nombre,
                nombre_mostrable,
                detalle,
                informativo,
                tema_activo,
                peso,
                orden
            }
        }).then(() => {
            Swal.fire({
                heightAuto: false,
                icon: 'success',
                title: '¡Listo!',
                text: 'El Tema se ha guardado correctamente'
            });
        }).catch((error) =>
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: 'Error al guardar el tema',
                text: error.response.data.message
            })
        )
            .finally(() => {
                setSaveLoading(false);
                detailModal.current?.close();
                setOpen(false);
                setTema(null);
                setNombre('');
                setNombreMostrable('');
                setDetalle('');
                setInformativo(false);
                setTemaActivo(true);
                setPeso(1);
                setOrden(1);
                descargarTemas();
            });
    }

    const editarTema = (t: Tema) => {
        setTema(t);
        detailModal.current?.showModal();
        setNombre(t.nombre || '');
        setNombreMostrable(t.nombre_mostrable || '');
        setDetalle(t.detalle || '');
        setInformativo(t.informativo || false);
        setTemaActivo(t.tema_activo || false);
        setPeso(t.peso || 0);
        setOrden(t.orden || 0);
        setOpen(true);
    }

    const eliminarTema = (t: Tema) => {
        Swal.fire({
            heightAuto: false,
            icon: 'warning',
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará el tema de forma permanente.',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/api/cuestionarios/${cuestionarioId}/temas/${t.id}`).then(() => {
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        title: '¡Listo!',
                        text: 'El tema se ha eliminado correctamente'
                    });
                }).finally(() => {
                    descargarTemas();
                });
            }
        });
    }

    useEffect(() => {
        reset();
        descargarInfoCuestionario();
        descargarTemas();
    }, [cuestionarioId, limit, page, search, open]);

    const cerrarModal = () => {
        setNombre('');
        setNombreMostrable('');
        setDetalle('');
        setInformativo(false);
        setTemaActivo(true);
        setPeso(1);
        setOrden(1);
        setOpen(false);
        detailModal.current?.close();
    }

    const debounced = useDebouncedCallback((value) => {
        setSearch(value);
    }, 500);


    return (
        <div className={styles.cuestionario_wrapper}>
            <h3 className={styles.title}>Temas del cuestionario {cuestionario?.nombre || 'Cargando...'}</h3>
            <div className={styles.actions}>
                <Button onClick={() => navigate(-1)} color="ghost" size='sm'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z" /></svg>
                </Button>
                <Input className='w-full' placeholder="Buscar" size="sm" onChange={(e) => debounced(e.target.value)} />
                <Button color="primary" size='sm' onClick={() => setOpen(true)}>Agregar tema</Button>
            </div>
            <div className="h-full" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <div className="scrollbar-none">
                    {loading ? <div className={styles.loading}><Loading color="primary" /></div> :
                        <Table zebra pinRows>
                            <Table.Head className="th_acciones">
                                <span>Nombre</span>
                                <span>Nombre mostrable</span>
                                <span>Detalle</span>
                                <span>Informativo</span>
                                <span>Peso</span>
                                <span>Orden</span>
                                <span>Estado</span>
                                <span>Acciones</span>
                            </Table.Head>

                            <Table.Body>
                                {temas.map((tema, item) => (
                                    <Table.Row key={item} hover>
                                        <span>{tema.nombre}</span>
                                        <span>{tema.nombre_mostrable}</span>
                                        <span className="line-clamp">{tema.detalle}</span>
                                        <span>{tema.informativo === true ? <Badge color="success">Si</Badge> : <Badge color="error">No</Badge>}</span>
                                        {!tema.informativo ? (
                                            <span>
                                                <Rating value={tema.peso || 0} color="warning" size="sm">
                                                    <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                                    <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                                    <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                                    <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                                    <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                                </Rating>
                                            </span>
                                        ) : (
                                            <span>-</span>
                                        )}
                                        <span>{tema.orden}</span>
                                        <span>{tema.tema_activo === true ? <Badge color="success">Activo</Badge> : <Badge color="error">Inactivo</Badge>}</span>
                                        <span>
                                            <div className="flex space-x-2 items-center gap-2">
                                                <Tooltip message="Preview">
                                                    <Button size='sm' shape="square" color='success' onClick={() => navigate(`/cuestionarios/${cuestionarioId}/temas/${tema.id}/preview`)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-134 0-244.5-72T61-462q-5-9-7.5-18.5T51-500q0-10 2.5-19.5T61-538q64-118 174.5-190T480-800q134 0 244.5 72T899-538q5 9 7.5 18.5T909-500q0 10-2.5 19.5T899-462q-64 118-174.5 190T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip message="Preguntas">
                                                    <Button size="sm" shape="square" color="primary" onClick={() => navigate(`/cuestionarios/${cuestionarioId}/temas/${tema.id}/preguntas`)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M560-360q17 0 29.5-12.5T602-402q0-17-12.5-29.5T560-444q-17 0-29.5 12.5T518-402q0 17 12.5 29.5T560-360Zm0-128q11 0 20.5-8t11.5-21q2-12 8.5-22t23.5-27q30-30 40-48.5t10-43.5q0-45-31.5-73.5T560-760q-33 0-60 15t-43 43q-6 10-1 21t17 16q11 5 21.5 1t17.5-14q9-13 21-19.5t27-6.5q24 0 39 13.5t15 36.5q0 14-8 26.5T578-596q-29 25-37 38.5T531-518q-1 12 7.5 21t21.5 9ZM320-240q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-520q0-17 11.5-28.5T120-720q17 0 28.5 11.5T160-680v520h520q17 0 28.5 11.5T720-120q0 17-11.5 28.5T680-80H160Zm160-720v480-480Z" /></svg>
                                                    </Button>
                                                </Tooltip>

                                                <Tooltip message="Editar">
                                                    <Button size="sm" shape="square" color="secondary" onClick={() => editarTema(tema)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"><path d="M200-200h57l391-391-57-57-391 391v57Zm-40 80q-17 0-28.5-11.5T120-160v-97q0-16 6-30.5t17-25.5l505-504q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L313-143q-11 11-25.5 17t-30.5 6h-97Zm600-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                                    </Button>
                                                </Tooltip>

                                                <Tooltip message="Eliminar">
                                                    <Button size="sm" shape="square" color="error" onClick={() => eliminarTema(tema)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM400-280q17 0 28.5-11.5T440-320v-280q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v280q0 17 11.5 28.5T400-280Zm160 0q17 0 28.5-11.5T600-320v-280q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v280q0 17 11.5 28.5T560-280ZM280-720v520-520Z" /></svg>
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </span>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    }
                </div>
            </div>

            <div className={styles.pagination}>
                <div className={styles.cantidad}>
                    <small >Cantidad por página:</small>
                    <Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </Select>
                </div>
                <Pagination className="flex justify-center">
                    <Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
                        Anterior
                    </Button>
                    {renderPageNumbers()}
                    <Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
                        Siguiente
                    </Button>
                </Pagination>
            </div>
            <Modal open={open} ref={detailModal}>
                <form method="dialog">
                    <Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }} onClick={cerrarModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                    </Button>
                </form>
                <Modal.Header className="font-bold">{nombre}</Modal.Header>
                <Modal.Body>
                    {saveLoading ? <Loading color='primary' /> : <>
                        <form onSubmit={handleSubmit(guardarTema)}>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Nombre</span>
                                </label>
                                <Input
                                    color={errors.nombre ? 'error' : 'neutral'}
                                    placeholder="Nombre"
                                    {...register('nombre')}
                                    onChange={(e) => setNombre(e.target.value)}
                                    value={nombre}
                                />
                                {errors.nombre && <span className="label-text-alt text-error">{errors.nombre.message}</span>}
                            </div>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Nombre mostrable</span>
                                </label>
                                <Input
                                    color={errors.nombre_mostrable ? 'error' : 'neutral'}
                                    placeholder="Nombre mostrable"
                                    {...register('nombre_mostrable')}
                                    onChange={(e) => setNombreMostrable(e.target.value)}
                                    value={nombre_mostrable}
                                />
                                {errors.nombre_mostrable && <span className="label-text-alt text-error">{errors.nombre_mostrable.message}</span>}
                            </div>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Detalle</span>
                                </label>
                                <Textarea color={errors.detalle ? 'error' : 'neutral'} {...register('detalle')} placeholder="Detalle" className="input input-bordered" onChange={(e) => setDetalle(e.target.value)} value={detalle} />
                                {errors.detalle && <span className="label-text-alt text-error">{errors.detalle.message}</span>}
                            </div>
                            <div className={styles.columns}>
                                <div className="form-control mb-2">
                                    <label className="label">
                                        <span className="label-text">Orden</span>
                                    </label>
                                    <Input size="sm" type="number" style={{ width: '100%' }} placeholder="Orden" className="input input-bordered" onChange={(e) => setOrden(Number(e.target.value))} value={orden} min={1} />
                                </div>
                                {!informativo &&
                                    <div className="form-control mb-2">
                                        <label className="label">
                                            <span className="label-text">Peso</span>
                                        </label>
                                        <Rating value={peso} onChange={(newRating: number) => setPeso(newRating)}>
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                        </Rating>
                                    </div>
                                }
                            </div>
                            <div className={styles.checkboxs}>
                                <div className={['form-control mb-2', styles.checkbox].join(' ')}>
                                    <Checkbox color="primary" checked={informativo} onChange={(e) => setInformativo(e.target.checked)} id="informativo" />
                                    <label className="label" htmlFor="informativo">
                                        <span className="label-text" style={{ fontSize: '18px' }}>Informativo</span>
                                    </label>
                                </div>
                                <div className={['form-control mb-2', styles.checkbox].join(' ')}>
                                    <Checkbox color="primary" checked={tema_activo} onChange={(e) => setTemaActivo(e.target.checked)} id="activo" />
                                    <label className="label" htmlFor="activo">
                                        <span className="label-text" style={{ fontSize: '18px' }}>Activo</span>
                                    </label>
                                </div>
                            </div>

                        </form>
                    </>}
                </Modal.Body>
                <Modal.Actions>
                    <Button color="primary" fullWidth onClick={handleSubmit(guardarTema)}>Guardar</Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}