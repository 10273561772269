import { Button, Input, Loading, Modal, Pagination, Select, Table, Tooltip } from 'react-daisyui';
import styles from './Empresas.module.scss';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Empresa } from './interface';
import Swal from 'sweetalert2';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

type EmpresasSchemaType = Yup.InferType<typeof empresasSchema>;

const empresasSchema = Yup.object().shape({
    nombre: Yup.string()
        .required('Campo requerido')
        .min(2, 'Mínimo 2 caracteres'),
});

export default function Empresas() {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [empresas, setEmpresas] = useState<Array<Empresa>>([]);
    const [empresa, setEmpresa] = useState<Empresa | null>(null);

    const [nombre, setNombre] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [nit, setNit] = useState<string>('');
    const [promedio_trabajadores, setPromedioTrabajadores] = useState<number>(0);
    const [inicio_diligenciamiento, setInicioDiligenciamiento] = useState<Date>(new Date());
    const [search, setSearch] = useState<string>('');
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)

    const detailModal = useRef<HTMLDialogElement>(null);
    const navigate = useNavigate();

    const {
		register,
		handleSubmit,
		reset,
        setValue,
		formState: { errors }
	} = useForm<EmpresasSchemaType>({
		resolver: yupResolver(empresasSchema)
	});

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;
        const totalPages = Math.ceil(total / limit);
        const maxPage = Math.min(totalPages, maxPageButtons);
        const middlePage = Math.ceil(maxPage / 2);
        const startPage = Math.max(1, page - middlePage);
        const endPage = Math.min(totalPages, startPage + maxPage - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
                    {i}
                </Button>
            );
        }

        return pageNumbers;
    }

    const descargarEmpresas = async () => {
        setSaveLoading(true);
        axios.get("/api/empresas?offset=" + (page - 1) * limit + "&limit=" + limit + (search ? "&filters[search]=" + search : '')).then((response) => {
            setEmpresas(response.data.data);
            setTotal(response.data.total);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        reset();
        setNombre('');
        setEmail('');
        setNit('');
        setPromedioTrabajadores(0);
        setInicioDiligenciamiento(new Date());
        descargarEmpresas();
    }, [limit, page, search, open]);

    const save = async () => {
        axios(`/api/empresas${empresa && empresa.id ? `/${empresa.id}` : ''}`, {
            method: empresa && empresa.id ? 'PUT' : 'POST',
            data: {
                ...empresa,
                nombre,
                email,
                nit,
                promedio_trabajadores,
                inicio_diligenciamiento
            }
        }).then(() => {
            Swal.fire({
                heightAuto: false,
                icon: 'success',
                title: '¡Listo!',
                text: 'La empresa se ha guardado correctamente'
            });
        }).catch((e) =>
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: 'Error al guardar la empresa',
                text: e.response.data.message
            }))
        .finally(() => {
            setSaveLoading(false);

            detailModal.current?.close();
            setOpen(false);
            setEmpresa(null);
            setNombre('');
            setEmail('');
            setNit('');
            setPromedioTrabajadores(0);
            setInicioDiligenciamiento(new Date());
            descargarEmpresas();
        });
    }

    const editarEmpresa = (e: Empresa) => {
        setEmpresa(e);
        detailModal.current?.showModal();
        setNombre(e.nombre || '');
        setValue('nombre', e.nombre || '');
        setEmail(e.email || '');
        setNit(e.nit || '');
        setPromedioTrabajadores(e.promedio_trabajadores || 0);
        setInicioDiligenciamiento(e.inicio_diligenciamiento || new Date());
    }

    const eliminarEmpresa = (e: Empresa) => {
        Swal.fire({
            heightAuto: false,
            icon: 'warning',
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará la empresa de forma permanente.',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/api/empresas/${e.id}`).then(() => {
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        title: '¡Listo!',
                        text: 'La empresa se ha eliminado correctamente'
                    });
                }).finally(() => {
                    descargarEmpresas();
                });
            }
        });
    }

    const debounced = useDebouncedCallback((value) => {
        setSearch(value);
    }, 500);

    return (
        <>
            <div className={styles.empresas_wrapper}>
                <div className={styles.actions}>
                    <Input className='w-full' placeholder="Buscar" size="sm" onChange={(e) => debounced(e.target.value)} />
                    <Button color="primary" size='sm' onClick={() => setOpen(true)}>Agregar empresa</Button>
                </div>
                <div className="h-full" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                    <div className="scrollbar-none">
                        {loading ? <div className={styles.loading}><Loading color="primary" /></div> :
                            <Table zebra pinRows>
                                <Table.Head className="th_acciones">
                                    <span>Nombre</span>
                                    <span>Email</span>
                                    <span>NIT</span>
                                    <span>Prom. trabajadores</span>
                                    <span>Inicio</span>
                                    <span>Acciones</span>
                                </Table.Head>

                                <Table.Body>
                                    {empresas.map((e, item) => (
                                        <Table.Row key={item} hover>
                                            <span>{e.nombre}</span>
                                            <span>{e.email}</span>
                                            <span>{e.nit}</span>
                                            <span>{e.promedio_trabajadores}</span>
                                            <span>{e.inicio_formateado}</span>
                                            <span>
                                                <div className="flex space-x-2 items-center gap-2">
                                                    <Tooltip message="Reporte de resultados">
                                                        <Button size="sm" shape="square" color="primary" onClick={() => navigate(`/empresas/${e.id}/resultados`)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M280-280h80v-200h-80v200Zm320 0h80v-400h-80v400Zm-160 0h80v-120h-80v120Zm0-200h80v-80h-80v80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip message="Detalle">
                                                        <Button size='sm' shape="square" color='success' onClick={() => navigate(`/empresas/${e.id}`)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-134 0-244.5-72T61-462q-5-9-7.5-18.5T51-500q0-10 2.5-19.5T61-538q64-118 174.5-190T480-800q134 0 244.5 72T899-538q5 9 7.5 18.5T909-500q0 10-2.5 19.5T899-462q-64 118-174.5 190T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip message="Editar">
                                                        <Button size="sm" shape="square" color="secondary" onClick={() => editarEmpresa(e)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"><path d="M200-200h57l391-391-57-57-391 391v57Zm-40 80q-17 0-28.5-11.5T120-160v-97q0-16 6-30.5t17-25.5l505-504q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L313-143q-11 11-25.5 17t-30.5 6h-97Zm600-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                                        </Button>
                                                    </Tooltip>

                                                    <Tooltip message="Eliminar">
                                                        <Button size="sm" shape="square" color="error" onClick={() => eliminarEmpresa(e)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM400-280q17 0 28.5-11.5T440-320v-280q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v280q0 17 11.5 28.5T400-280Zm160 0q17 0 28.5-11.5T600-320v-280q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v280q0 17 11.5 28.5T560-280ZM280-720v520-520Z" /></svg>
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </span>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        }
                    </div>
                </div>

                <div className={styles.pagination}>
                    <div className={styles.cantidad}>
                        <small >Cantidad por página:</small>
                        <Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </Select>
                    </div>
                    <Pagination className="flex justify-center">
                        <Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
                            Anterior
                        </Button>
                        {renderPageNumbers()}
                        <Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
                            Siguiente
                        </Button>
                    </Pagination>
                </div>
                <Modal open={open} ref={detailModal}>
                    <form method="dialog">
                        <Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }} onClick={() => setOpen(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                        </Button>
                    </form>
                    <Modal.Header className="font-bold">Nueva empresa</Modal.Header>
                    <Modal.Body>
                        {!saveLoading ? <Loading color='primary' /> : <>
                            <form onSubmit={handleSubmit(save)}>
                                <div className="form-control mb-2">
                                    <label className="label">
                                        <span className="label-text">Nombre</span>
                                    </label>
                                    <Input
                                        type="text"
                                        color={errors.nombre ? 'error' : 'neutral'}
                                        placeholder="Nombre"
                                        className="input input-bordered"
                                        {...register('nombre')}
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                    {errors.nombre && <span className="label-text-alt text-error">{errors.nombre.message}</span>}
                                </div>
                                <div className="form-control mb-2">
                                    <label className="label">
                                        <span className="label-text">Email</span>
                                    </label>
                                    <input type="text" placeholder="Email" className="input input-bordered" onChange={(e) => setEmail(e.target.value)} value={email} />
                                </div>
                                <div className="form-control mb-2">
                                    <label className="label">
                                        <span className="label-text">NIT</span>
                                    </label>
                                    <input type="text" placeholder="NIT" className="input input-bordered" onChange={(e) => setNit(e.target.value)} value={nit} />
                                </div>
                                <div className="form-control mb-2">
                                    <label className="label">
                                        <span className="label-text">Promedio de trabajadores</span>
                                    </label>
                                    <input type="number" placeholder="Promedio de trabajadores" className="input input-bordered" onChange={(e) => setPromedioTrabajadores(parseInt(e.target.value))} value={promedio_trabajadores} />
                                </div>
                                <div className="form-control mb-2">
                                    <label className="label">
                                        <span className="label-text">Inicio de diligenciamiento</span>
                                    </label>
                                    <input type="date" placeholder="Inicio de diligenciamiento" className="input input-bordered" onChange={(e) => setInicioDiligenciamiento(new Date(e.target.value))} value={inicio_diligenciamiento ? new Date(inicio_diligenciamiento).toISOString().split('T')[0] : ''} />
                                </div>
                            </form>
                        </>}
                    </Modal.Body>
                    <Modal.Actions>
                        <Button color="primary" fullWidth onClick={handleSubmit(save)}>Guardar</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </>
    );
}
