import axios from "axios";
import { useEffect, useState } from "react";
import { Badge, Button, Rating } from "react-daisyui";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Preview.module.scss";

export default function PreviewTema() {
    const { cuestionarioId, temaId } = useParams()
    const [preview, setPreview] = useState<any>(null);
    const navigate = useNavigate();

    const descargarInfoPreview = () => {
        axios.get(`/api/cuestionarios/${cuestionarioId}/temas/${temaId}/preview`)
            .then((response) => {
                setPreview(response.data.tema);
                console.log(response.data.tema);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    useEffect(() => {
        descargarInfoPreview();
    }, [])

    return (
        <div className={styles.preview_wrapper}>
            <div className={styles.actions}>
                <Button onClick={() => navigate(-1)} color="ghost" size='sm'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z" /></svg>
                    Volver
                </Button>
            </div>

            <div className={styles.preview}>
                <h1 className={styles.nombre}>{preview?.nombre_mostrable}</h1>
                <p className={styles.detalle}>{preview?.detalle}</p>

                <div className={styles.preguntas}>
                    <ul className={styles.pregunta}>
                        {preview?.preguntas.map((pregunta: any) => (
                            <li key={pregunta.id_pregunta} style={{ marginLeft: pregunta.sub_orden > 0 ? '2.5rem' : '0px' }}>
                                <p>
                                    <span className={styles.numero}>{pregunta.orden}.{pregunta.sub_orden} </span>
                                    {pregunta.texto_pregunta}
                                </p>
                                <div className={styles.info}>
                                    <span className="flex items-center gap-2">Peso:
                                        <Rating value={pregunta.peso || 0} color="warning" size="sm" className="items-center">
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                        </Rating>
                                    </span>
                                    {pregunta.informativa && <Badge color="info">Informativa</Badge>}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}