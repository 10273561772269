import ReactDOM from 'react-dom/client'
import './index.css'
import './App.css'
import Routes from './routes/Routes.tsx'
import { AuthProvider } from './providers/AuthContext.tsx'
//import * as Sentry from "@sentry/react";

/*Sentry.init({
	dsn: "https://45c2bdf7b1edc0f7f04089e29e3ce4a5@o4508094789779456.ingest.us.sentry.io/4508094986846208",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", "https://reportes.alpesenergy.com/"],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});*/

ReactDOM.createRoot(document.getElementById('root')!).render(
	<AuthProvider >
		<Routes />
	</AuthProvider>
)
