import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { Badge, Button, Form, Loading, Radio, Tooltip } from "react-daisyui"
import GaugeComponent from "react-gauge-component"
import { useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { useAuth } from "../../providers/AuthContext"
import styles from "./EncuestaDetalle.module.scss"
import { Pregunta, Respuesta, type Encuestado, type Tema } from "./interface"

export default function EncuestaDetalle() {
    const [loading, setLoading] = useState<boolean>(false)
    const [encuesta, setEncuesta] = useState<Encuestado>({});
    const [temas, setTemas] = useState<Tema[]>([]);
    const [selectedTema, setSelectedTema] = useState<Tema | null>(null);
    const [showSidebar, setShowSidebar] = useState(false)
    const [arcos, setArcos] = useState({});
    const [verMas, setVerMas] = useState(false);
    const [mostrarBoton, setMostrarBoton] = useState(false);
    const contenedorRef = useRef<HTMLDivElement>(null);

    const { cuestionarioId } = useParams()
    const { user } = useAuth();

    const descargarEncuesta = () => {
        setLoading(true);
        axios.get(`/api/encuestas/${user?.id}/${cuestionarioId}`)
            .then((response) => {
                setEncuesta(response.data);
            })
            .catch((error) => {
                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: 'Error al obtener la encuesta',
                    text: error.response.data.message
                })
            })
            .finally(() => {
                setLoading(false);
            })
        /**/
    }

    const descargarPreguntas = () => {
        setLoading(true);
        axios.get(`/api/encuestas/${user?.id}/${cuestionarioId}/preguntas`).then((response) => {
            setTemas(response.data);
        }).catch((error) => {
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: 'Error al obtener las preguntas',
                text: error.response.data.message
            })
        })
            .finally(() => {
                setLoading(false);
            })
    }

    const iniciarEncuesta = (id_cuestionario_cliente: string, id_cliente: string) => {
        axios(`/api/encuestas/${id_cliente}/${id_cuestionario_cliente}/iniciar`, {
            method: 'PUT',
            data: {}
        }).then(() => {
            Swal.fire({
                heightAuto: false,
                icon: 'success',
                title: 'Encuesta iniciada',
                text: 'Encuesta iniciada con exito',
            })
        }).catch((error) => {
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: 'Error al iniciar la encuesta',
                text: error.response.data.message
            })
        }).finally(() => {
            descargarEncuesta();
            setLoading(false);
        })
    }

    const cerrarEncuesta = (id_cuestionario_cliente: string, id_cliente: string) => {
        let preguntas_sin_responder = new Array<Pregunta>();
        temas.forEach(tema => {
            if(tema.preguntas){
                tema?.preguntas.forEach(pregunta => {
                    if (pregunta.respuesta_usuario == null && pregunta.no_aplica == null) {
                        preguntas_sin_responder.push(pregunta);
                    }
                    if(pregunta.subpreguntas){
                        pregunta?.subpreguntas.forEach(subpregunta => {
                            if (subpregunta.respuesta_usuario == null && subpregunta.no_aplica == null) {
                                preguntas_sin_responder.push(subpregunta);
                            }
                        })
                    }
                })
            }
        })
        //console.log(preguntas_sin_responder);
        //return
        let total_preguntas_sin_responder = preguntas_sin_responder.length;
        let texto_preguntas_sin_responder = '<p><strong>Una vez cerrada no podrás revertir esta acción.</strong></p>';
        if(total_preguntas_sin_responder > 0){
            texto_preguntas_sin_responder = `<p><strong>Existen ${total_preguntas_sin_responder} preguntas sin responder: </strong></p> 
                                                ${preguntas_sin_responder.map((pregunta) => `<p>${pregunta.texto_pregunta}</p>`).join('')}
                                            <p><strong>Una vez cerrada no podrás revertir esta acción.</strong></p>`
            
        }
        Swal.fire({
            heightAuto: false,
            icon: 'warning',
            title: '¿Deseas cerrar la encuesta?',
            html: texto_preguntas_sin_responder,
            showCancelButton: true,
            confirmButtonText: 'Sí, cerrar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios(`/api/encuestas/${id_cliente}/${id_cuestionario_cliente}/entregar`, {
                    method: 'PUT',
                    data: {}
                }).then(() => {
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        title: 'Encuesta cerrada',
                        text: 'Encuesta cerrada con éxito',
                    });
                }).catch((error) => {
                    Swal.fire({
                        heightAuto: false,
                        icon: 'error',
                        title: 'Error al cerrar la encuesta',
                        text: error.response.data.message,
                    });
                }).finally(() => {
                    descargarEncuesta();
                    setLoading(false);
                    setSelectedTema(null);
                });
            }
        });
    };

    const mostarDetalle = (texto: string) => {
        Swal.fire({
            heightAuto: false,
            icon: 'info',
            text: texto
        })
    }

    const abrirModalPreguntaInformativa = (pregunta: any) => {
        Swal.fire({
            heightAuto: false,
            text: pregunta.texto_pregunta,
            input: 'textarea',
            confirmButtonText: 'Guardar',
            inputValue: pregunta.respuesta_libre,

            preConfirm: () => {
                responderPregunta(pregunta, 'informativa', Swal.getInput()?.value || '');
            },
        })
    }

    const responderPregunta = (pregunta: Pregunta, id_respuesta: string, respuesta_libre: string): void => {
        let body = new Respuesta;
        switch (id_respuesta) {
            case 'si':
                body.respuesta_usuario = true;
                break;
            case 'no':
                body.respuesta_usuario = false;
                break;
            case 'no-aplica':
                body.no_aplica = true;
                break;
            case 'informativa':
                body.respuesta_libre = respuesta_libre;
                break;
        }

        axios(`/api/encuestas/responder-pregunta/${encuesta.id_cliente}/${encuesta.id_cuestionario_cliente}/${pregunta.id}`, {
            method: 'PUT',
            data: body
        }).then(() => {
            let auxTemas = [...temas];
            auxTemas.forEach((tema) => {
                if (tema.id_tema === pregunta.id_tema) {
                    tema.preguntas?.forEach((preg) => {
                        if (pregunta.id === preg.id) {
                            switch (id_respuesta) {
                                case 'si':
                                    pregunta.respuesta_usuario = true;
                                    break;
                                case 'no':
                                    pregunta.respuesta_usuario = false;
                                    break;
                                case 'no-aplica':
                                    pregunta.respuesta_usuario = undefined;
                                    pregunta.no_aplica = true;
                                    break;
                                case 'informativa':
                                    pregunta.respuesta_libre = respuesta_libre;
                                    break;
                            }
                        }
                        preg.subpreguntas?.forEach((subpreg) => {
                            if (pregunta.id === subpreg.id) {
                                switch (id_respuesta) {
                                    case 'si':
                                        pregunta.respuesta_usuario = true;
                                        break;
                                    case 'no':
                                        pregunta.respuesta_usuario = false;
                                        break;
                                    case 'no-aplica':
                                        pregunta.no_aplica = true;
                                        break;
                                    case 'informativa':
                                        pregunta.respuesta_libre = respuesta_libre;
                                        break;
                                }
                            }
                        })
                    })
                }
            })
            setTemas(auxTemas);
        }).catch((error) => {
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: 'Error al guardar la respuesta',
                text: error.response.data.message
            })
        }).finally(() => {
            return;
        })
    }


    const dibujarTemas = () => {
        return temas.map((tema) => {
            return (
                <Button key={tema.id_tema} fullWidth color="ghost" onClick={() => seleccionarTema(tema)} className={[styles.btn_tema, tema.id_tema === selectedTema?.id_tema ? styles.active : ''].join(' ')}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m382-354 339-339q12-12 28-12t28 12q12 12 12 28.5T777-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z" /></svg>
                    {tema.nombre_mostrable}
                </Button>
            )
        })
    }

    const seleccionarTema = (tema: any) => {
        const temaFiltrado = temas.find((t) => t.id_tema === tema.id_tema)
        setSelectedTema(temaFiltrado || null);
    }

    const renderParrafos = (text: string) => {
        return text.split('\n').map((str, index) => (
            <p className="text-xl" key={'p' + index}>
                {str}
            </p>
        ));
    };

    useEffect(() => {
        if (contenedorRef.current) {
            const alturaReal = contenedorRef.current.scrollHeight;
            const alturaVisible = contenedorRef.current.clientHeight;

            setMostrarBoton(alturaReal > alturaVisible);
        }
    }, [encuesta.descripcion]);

    useEffect(() => {
        descargarEncuesta();
        setArcos({
            subArcs: [
                {
                    limit: 50,
                    color: '#EA4228',
                    showTick: true
                },
                {
                    limit: 85,
                    color: '#F5CD19',
                    showTick: true
                },
                {
                    limit: 100,
                    color: '#5BE12C',
                    showTick: true
                },
            ]
        });
    }, [])

    useEffect(() => {
        descargarPreguntas();
    }, [encuesta])

    const badgeColorEstado = (estado: number) => {
        const estados: { [key: number]: JSX.Element } = {
            1: <Badge color="warning">Pendiente de responder</Badge>,
            2: <Badge color="primary">Iniciado</Badge>,
            3: <Badge color="success">Entregado</Badge>,
        };

        return estados[estado] || '';
    };

    return (
        <div className={styles.encuestas_wrapper}>
            <div className={styles.encuestas_container}>
                <div className={styles.encuestas}>
                    {loading ? <div className={styles.loading}><Loading color="primary" /></div> :
                        !selectedTema && <div className={styles.encuesta}>
                            {encuesta.imagen &&
                                <img className={styles.imagen} src={`/api/cuestionarios/imagen/${encuesta.imagen}`} alt="" />
                            }
                            <h2 className={styles.titulo}>{encuesta.nombre} {badgeColorEstado(Number(encuesta.id_estado))}</h2>
                            <div
                                ref={contenedorRef}
                                className={`overflow-hidden ${Number(encuesta.id_estado) === 3 && !verMas ? "line-clamp-2" : ""}`}
                                style={{ maxHeight: !verMas && Number(encuesta.id_estado) === 3 ? '4.5em' : 'none' }}>
                                {renderParrafos(encuesta.descripcion ?? '')}
                            </div>
                            {Number(encuesta.id_estado) === 3 && mostrarBoton && (
                                <button
                                    onClick={() => setVerMas(!verMas)}
                                    className="text-blue-500 mt-2 underline"
                                    style={{
                                        textAlign: 'right'
                                    }}
                                >
                                    {verMas ? "Ver menos" : "Ver más"}
                                </button>
                            )}
                            {Number(encuesta.id_estado) == 1 &&
                                <Button wide animation size="md" color="success" style={{ margin: 'auto' }} onClick={() => iniciarEncuesta(encuesta.id_cuestionario_cliente ?? '', encuesta.id_cliente ?? '')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M320-273v-414q0-17 12-28.5t28-11.5q5 0 10.5 1.5T381-721l326 207q9 6 13.5 15t4.5 19q0 10-4.5 19T707-446L381-239q-5 3-10.5 4.5T360-233q-16 0-28-11.5T320-273Zm80-207Zm0 134 210-134-210-134v268Z" /></svg>
                                    Comenzar
                                </Button>
                            }
                            {Number(encuesta.id_estado) == 3 &&
                                <GaugeComponent arc={arcos} value={encuesta.resultado} className={styles.grafico} />
                            }
                        </div>
                    }
                </div>
                {((Number(encuesta.id_estado) == 2 || Number(encuesta.id_estado) == 3) && selectedTema) &&
                    <div key={selectedTema?.id_tema} className={styles.tema}>
                        <div className={styles.informacion}>
                            <div>
                                <h3 className={styles.titulo}>{selectedTema.nombre_mostrable}</h3>
                                {renderParrafos(selectedTema.detalle ?? '')}
                            </div>
                            {Number(encuesta.id_estado) == 3 &&
                                <GaugeComponent arc={arcos} value={selectedTema.resultado_tema} className={styles.grafico} />
                            }
                        </div>

                        <ul className={styles.preguntas}>
                            {selectedTema.preguntas?.map((pregunta, index) => (
                                <div key={index}>
                                    <li key={index} className={styles.pregunta}>
                                        <span className={styles.numero}>
                                            {pregunta.orden}
                                        </span>
                                        {pregunta.texto_pregunta}
                                    </li>
                                    {pregunta.respuesta_libre && <div className="flex gap-2 mb-2"><strong>Respuesta:</strong> <p>{pregunta.respuesta_libre}</p></div>}
                                    <div className={styles.respuestas_wrapper}>
                                        {!pregunta.informativa &&
                                            <Form className={styles.respuestas}>
                                                <Form.Label title="Si">
                                                    <Radio color={(Number(encuesta.id_estado) !== 3 || pregunta.no_aplica) ? "primary" : (pregunta.respuesta_usuario === pregunta.respuesta_correcta) ? "success" : "error"} size="sm" name={`radio-${pregunta.id}`} onClick={() => { responderPregunta(pregunta, 'si', '') }} defaultChecked={pregunta.respuesta_usuario ? true : false} disabled={Number(encuesta.id_estado) === 3} />
                                                </Form.Label>
                                                <Form.Label title="No">
                                                    <Radio color={(Number(encuesta.id_estado) !== 3 || pregunta.no_aplica) ? "primary" : (pregunta.respuesta_usuario === pregunta.respuesta_correcta) ? "success" : "error"} size="sm" name={`radio-${pregunta.id}`} onClick={() => { responderPregunta(pregunta, 'no', '') }} defaultChecked={!pregunta.respuesta_usuario && pregunta.respuesta_usuario !== null ? true : false} disabled={Number(encuesta.id_estado) === 3} />
                                                </Form.Label>
                                                <Form.Label title="No Aplica">
                                                    <Radio color="primary" size="sm" name={`radio-${pregunta.id}`} onClick={() => { responderPregunta(pregunta, 'no-aplica', '') }} defaultChecked={pregunta.no_aplica ? true : false} disabled={Number(encuesta.id_estado) === 3} />
                                                </Form.Label>
                                            </Form>
                                        }
                                        {pregunta.informativa &&
                                            <Button size="sm" color="secondary" onClick={() => abrirModalPreguntaInformativa(pregunta)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#c3c8c6"><path d="M160-120v-170l527-526q12-12 27-18t30-6q16 0 30.5 6t25.5 18l56 56q12 11 18 25.5t6 30.5q0 15-6 30t-18 27L330-120H160Zm80-80h56l393-392-28-29-29-28-392 393v56Zm560-503-57-57 57 57Zm-139 82-29-28 57 57-28-29ZM560-120q74 0 137-37t63-103q0-36-19-62t-51-45l-59 59q23 10 36 22t13 26q0 23-36.5 41.5T560-200q-17 0-28.5 11.5T520-160q0 17 11.5 28.5T560-120ZM183-426l60-60q-20-8-31.5-16.5T200-520q0-12 18-24t76-37q88-38 117-69t29-70q0-55-44-87.5T280-840q-45 0-80.5 16T145-785q-11 13-9 29t15 26q13 11 29 9t27-13q14-14 31-20t42-6q41 0 60.5 12t19.5 28q0 14-17.5 25.5T262-654q-80 35-111 63.5T120-520q0 32 17 54.5t46 39.5Z" /></svg>
                                                Responder
                                            </Button>
                                        }
                                        {pregunta.detalle &&
                                            <Tooltip message="Detalle">
                                                <Button size="sm" shape="square" color="info" onClick={() => mostarDetalle(pregunta.detalle ?? '')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="22px" fill="currentColor"><path d="M480-280q17 0 28.5-11.5T520-320v-160q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480v160q0 17 11.5 28.5T480-280Zm0-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                </Button>
                                            </Tooltip>
                                        }
                                    </div>
                                    {(pregunta.respuesta_usuario) &&
                                        <div className={styles.subpreguntas}>
                                            {pregunta.subpreguntas?.map((subpregunta, i) => (
                                                //Inicio de subpregunta
                                                <div style={{ marginLeft: '2.5rem' }} key={'sp-' + i}>
                                                    <li key={'sp-' + i} className={styles.pregunta}>
                                                        <span className={styles.numero}>
                                                            {subpregunta.orden}.{subpregunta.sub_orden}
                                                        </span>
                                                        {subpregunta.texto_pregunta}
                                                    </li>
                                                    {subpregunta.respuesta_libre && <div className="flex gap-2 mb-2"><strong>Respuesta:</strong> <p>{subpregunta.respuesta_libre}</p></div>}
                                                    <div className={styles.respuestas_wrapper}>
                                                        {!subpregunta.informativa &&
                                                            <Form className={styles.respuestas}>
                                                                <Form.Label title="Si">
                                                                    <Radio color={Number(encuesta.id_estado) !== 3 ? "primary" : (subpregunta.respuesta_usuario === subpregunta.respuesta_correcta) ? "success" : "error"} size="sm" name={`radio-${subpregunta.id}`} onClick={() => { responderPregunta(subpregunta, 'si', '') }} defaultChecked={subpregunta.respuesta_usuario ? true : false} disabled={Number(encuesta.id_estado) === 3} />
                                                                </Form.Label>
                                                                <Form.Label title="No">
                                                                    <Radio color={Number(encuesta.id_estado) !== 3 ? "primary" : (subpregunta.respuesta_usuario === subpregunta.respuesta_correcta) ? "success" : "error"} size="sm" name={`radio-${subpregunta.id}`} onClick={() => { responderPregunta(subpregunta, 'no', '') }} defaultChecked={!subpregunta.respuesta_usuario && subpregunta.respuesta_usuario !== null ? true : false} disabled={Number(encuesta.id_estado) === 3} />
                                                                </Form.Label>
                                                                <Form.Label title="No Aplica">
                                                                    <Radio color="primary" size="sm" name={`radio-${subpregunta.id}`} onClick={() => { responderPregunta(subpregunta, 'no-aplica', '') }} defaultChecked={subpregunta.no_aplica ? true : false} disabled={Number(encuesta.id_estado) === 3} />
                                                                </Form.Label>
                                                            </Form>
                                                        }
                                                        {subpregunta.informativa &&
                                                            <Button size="sm" color="secondary" onClick={() => abrirModalPreguntaInformativa(subpregunta)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#c3c8c6"><path d="M160-120v-170l527-526q12-12 27-18t30-6q16 0 30.5 6t25.5 18l56 56q12 11 18 25.5t6 30.5q0 15-6 30t-18 27L330-120H160Zm80-80h56l393-392-28-29-29-28-392 393v56Zm560-503-57-57 57 57Zm-139 82-29-28 57 57-28-29ZM560-120q74 0 137-37t63-103q0-36-19-62t-51-45l-59 59q23 10 36 22t13 26q0 23-36.5 41.5T560-200q-17 0-28.5 11.5T520-160q0 17 11.5 28.5T560-120ZM183-426l60-60q-20-8-31.5-16.5T200-520q0-12 18-24t76-37q88-38 117-69t29-70q0-55-44-87.5T280-840q-45 0-80.5 16T145-785q-11 13-9 29t15 26q13 11 29 9t27-13q14-14 31-20t42-6q41 0 60.5 12t19.5 28q0 14-17.5 25.5T262-654q-80 35-111 63.5T120-520q0 32 17 54.5t46 39.5Z" /></svg>
                                                                Responder
                                                            </Button>
                                                        }
                                                        {subpregunta.detalle &&
                                                            <Tooltip message="Detalle">
                                                                <Button size="sm" shape="square" color="info" onClick={() => mostarDetalle(subpregunta.detalle ?? '')}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="22px" fill="currentColor"><path d="M480-280q17 0 28.5-11.5T520-320v-160q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480v160q0 17 11.5 28.5T480-280Zm0-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                                </Button>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                </div>

                                                //Fin de subpregunta
                                            ))}
                                        </div>
                                    }
                                </div>

                            ))}
                        </ul>
                    </div>
                }
            </div>
            {(Number(encuesta.id_estado) == 2 || Number(encuesta.id_estado) == 3) &&
                <div style={{ position: 'relative' }}>
                    <div className={[styles.sidebar_tema, showSidebar ? styles.show : ''].join(' ')}>
                        <Button onClick={() => setSelectedTema(null)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M240-200h120v-200q0-17 11.5-28.5T400-440h160q17 0 28.5 11.5T600-400v200h120v-360L480-740 240-560v360Zm-80 0v-360q0-19 8.5-36t23.5-28l240-180q21-16 48-16t48 16l240 180q15 11 23.5 28t8.5 36v360q0 33-23.5 56.5T720-120H560q-17 0-28.5-11.5T520-160v-200h-80v200q0 17-11.5 28.5T400-120H240q-33 0-56.5-23.5T160-200Zm320-270Z" /></svg>
                            Portada
                        </Button>
                        <div className={styles.temas}>
                            {dibujarTemas()}
                        </div>
                        {Number(encuesta.id_estado) == 2 &&
                            <Button color="success" onClick={() => cerrarEncuesta(encuesta.id_cuestionario_cliente ?? '', encuesta.id_cliente ?? '')}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h499q17 0 28.5 11.5T739-800q0 17-11.5 28.5T699-760H200v560h560v-260q0-17 11.5-28.5T800-500q17 0 28.5 11.5T840-460v260q0 33-23.5 56.5T760-120H200Zm261-272 340-340q11-11 27-11t28 11q12 11 12 28t-12 29L489-308q-12 12-28 12t-28-12L263-478q-11-11-11-28t11-28q11-11 28-11t28 11l142 142Z" /></svg>
                                Finalizar
                            </Button>
                        }
                    </div>
                    <button onClick={() => setShowSidebar(!showSidebar)} className={styles.toggle}>
                        {!showSidebar
                            ? <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m432-480 156 156q11 11 11 28t-11 28q-11 11-28 11t-28-11L348-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l184-184q11-11 28-11t28 11q11 11 11 28t-11 28L432-480Z" /></svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z" /></svg>
                        }
                    </button>
                </div>
            }
        </div>
    )
}