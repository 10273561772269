import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Avatar, Badge, Button, Checkbox, FileInput, Input, Loading, Mask, Modal, Pagination, Select, Table, Textarea, Tooltip } from "react-daisyui";
import { useNavigate } from "react-router-dom";
import { Cuestionario } from "./interface";
import Swal from "sweetalert2";
import styles from "./Cuestionarios.module.scss";
import { useDebouncedCallback } from "use-debounce";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

type CuestionariosSchemaType = Yup.InferType<typeof cuestionariosSchema>;

const cuestionariosSchema = Yup.object().shape({
    nombre: Yup.string()
        .required('Campo requerido')
        .min(2, 'Mínimo 2 caracteres'),
    descripcion: Yup.string()
        .max(2500, 'Máximo 2500 caracteres'),
})

export default function Cuestionarios() {
    const [loading, setLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [cuestionarios, setCuestionarios] = useState<Array<Cuestionario>>([]);
    const [cuestionario, setCuestionario] = useState<Cuestionario | null>(null);
    const [search, setSearch] = useState<string>('')
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(false)
    const detailModal = useRef<HTMLDialogElement>(null);
    const navigate = useNavigate();

    const [nombre, setNombre] = useState<string>('');
    const [descripcion, setDescripcion] = useState<string>('');
    const [imagen, setImagen] = useState<File>(new File([], ''));
    const [activo, setActivo] = useState<boolean>(true);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm<CuestionariosSchemaType>({
        resolver: yupResolver(cuestionariosSchema)
    });

    /**
     * Maneja el cambio de archivo en el input de tipo file.
     * @param {React.ChangeEvent<HTMLInputElement>} e - El evento de cambio de archivo.
     */
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const archivo = e.target.files?.[0]; // Solo tomar el primer archivo
        if (archivo) {
            setImagen(archivo); // Guardar el archivo en el estado
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;
        const totalPages = Math.ceil(total / limit);
        const maxPage = Math.min(totalPages, maxPageButtons);
        const middlePage = Math.ceil(maxPage / 2);
        const startPage = Math.max(1, page - middlePage);
        const endPage = Math.min(totalPages, startPage + maxPage - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
                    {i}
                </Button>
            );
        }

        return pageNumbers;
    }

    const descargarCuestionarios = async () => {
        setSaveLoading(true);
        axios.get("/api/cuestionarios?offset=" + (page - 1) * limit + "&limit=" + limit + (search ? "&filters[search]=" + search : '')).then((response) => {
            setCuestionarios(response.data.data);
            setTotal(response.data.total);
        }).finally(() => {
            setLoading(false);
        });
    }

    const guardarCuestionario = async () => {
        const formData = new FormData();
        formData.append('imagen', imagen); // 'image' es el nombre del campo en el backend
        formData.append('nombre', nombre);
        formData.append('descripcion', descripcion);
        formData.append('activo', activo.toString()); // Convierte el booleano a string
        setSaveLoading(true);
        axios.post(`/api/cuestionarios${cuestionario && cuestionario.id ? `/${cuestionario.id}` : ''}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Muy importante para enviar archivos
            },
        }).then(() => {
            Swal.fire({
                heightAuto: false,
                icon: 'success',
                title: '¡Listo!',
                text: 'El cuestionario se ha guardado correctamente'
            });
        }).catch((error) =>
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: 'Error al guardar el cuestionario',
                text: error.response.data.message
            }))
        .finally(() => {
            setSaveLoading(false);
            setOpen(false);
            detailModal.current?.close();
            setCuestionario(null);
            setNombre('');
            setDescripcion('');
            setImagen(new File([], ''));
            setActivo(true);
            descargarCuestionarios();
        });
    }

    const editarCuestionario = (c: Cuestionario) => {
        setCuestionario(c);
        setValue('nombre', c.nombre || '');
        setNombre(c.nombre || '');
        setDescripcion(c.descripcion || '');
        setImagen(c.imagen || new File([], ''));
        setActivo(c.activo || false);
        detailModal.current?.showModal();
        setOpen(true);
    }

    const eliminarCuestionario = (c: Cuestionario) => {
        Swal.fire({
            heightAuto: false,
            icon: 'warning',
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará el cuestionario de forma permanente.',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/api/cuestionarios/${c.id}`).then(() => {
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        title: '¡Listo!',
                        text: 'El cuestionario se ha eliminado correctamente'
                    });
                }).finally(() => {
                    descargarCuestionarios();
                });
            }
        });
    }

    useEffect(() => {
        reset();
        descargarCuestionarios();
    }, [limit, page, search]);


    const cerrarModal = () => {
        setOpen(false);
        detailModal.current?.close();
        setCuestionario(null);
        setNombre('');
        setDescripcion('');
        setImagen(new File([], ''));
        setActivo(true);
    };

    const debounced = useDebouncedCallback((value) => {
        setSearch(value);
    }, 500);

    return (
        <div className={styles.cuestionarios_wrapepr}>
            <div className={styles.actions}>
                <Input className='w-full' placeholder="Buscar por nombre" size="sm" onChange={(e) => debounced(e.target.value)} />
                <Button color="primary" size='sm' onClick={() => setOpen(true)}>Agregar cuestionario</Button>
            </div>
            <div className="h-full" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <div className="scrollbar-none">
                    {loading ? <div className={styles.loading}><Loading color="primary" /></div> :
                        <Table zebra pinRows>
                            <Table.Head className="th_acciones">
                                <span>Imágen</span>
                                <span>Nombre</span>
                                <span>Descripción</span>
                                <span>Estado</span>
                                <span>Acciones</span>
                            </Table.Head>

                            <Table.Body>
                                {cuestionarios.map((cuestionario, item) => (
                                    <Table.Row key={item} hover>
                                        <span>
                                            {cuestionario.imagen &&
                                                <Avatar size={'sm'} src={`/api/cuestionarios/imagen/${cuestionario.imagen}`} innerClassName={Mask.className({
                                                    variant: 'squircle'
                                                })} />
                                            }
                                        </span>
                                        <span>{cuestionario.nombre}</span>
                                        <span className="line-clamp">{cuestionario.descripcion}</span>
                                        <span>{cuestionario.activo === true ? <Badge color="success">Activo</Badge> : <Badge color="error">Inactivo</Badge>}</span>
                                        <span>
                                            <div className="flex space-x-2 items-center gap-2">
                                                <Tooltip message="Preview">
                                                    <Button size='sm' shape="square" color='success' onClick={() => navigate(`/cuestionarios/${cuestionario.id}/preview`)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-134 0-244.5-72T61-462q-5-9-7.5-18.5T51-500q0-10 2.5-19.5T61-538q64-118 174.5-190T480-800q134 0 244.5 72T899-538q5 9 7.5 18.5T909-500q0 10-2.5 19.5T899-462q-64 118-174.5 190T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip message="Temas">
                                                    <Button size='sm' shape="square" color='primary' onClick={() => navigate(`/cuestionarios/${cuestionario.id}`)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M320-600q-17 0-28.5-11.5T280-640q0-17 11.5-28.5T320-680h480q17 0 28.5 11.5T840-640q0 17-11.5 28.5T800-600H320Zm0 160q-17 0-28.5-11.5T280-480q0-17 11.5-28.5T320-520h480q17 0 28.5 11.5T840-480q0 17-11.5 28.5T800-440H320Zm0 160q-17 0-28.5-11.5T280-320q0-17 11.5-28.5T320-360h480q17 0 28.5 11.5T840-320q0 17-11.5 28.5T800-280H320ZM160-600q-17 0-28.5-11.5T120-640q0-17 11.5-28.5T160-680q17 0 28.5 11.5T200-640q0 17-11.5 28.5T160-600Zm0 160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440Zm0 160q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360q17 0 28.5 11.5T200-320q0 17-11.5 28.5T160-280Z" /></svg>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip message="Editar">
                                                    <Button size="sm" shape="square" color="secondary" onClick={() => editarCuestionario(cuestionario)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"><path d="M200-200h57l391-391-57-57-391 391v57Zm-40 80q-17 0-28.5-11.5T120-160v-97q0-16 6-30.5t17-25.5l505-504q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L313-143q-11 11-25.5 17t-30.5 6h-97Zm600-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                                    </Button>
                                                </Tooltip>

                                                <Tooltip message="Eliminar">
                                                    <Button size="sm" shape="square" color="error" onClick={() => eliminarCuestionario(cuestionario)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM400-280q17 0 28.5-11.5T440-320v-280q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v280q0 17 11.5 28.5T400-280Zm160 0q17 0 28.5-11.5T600-320v-280q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v280q0 17 11.5 28.5T560-280ZM280-720v520-520Z" /></svg>
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </span>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    }
                </div>
            </div>

            <div className={styles.pagination}>
                <div className={styles.cantidad}>
                    <small>Cantidad por página:</small>
                    <Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </Select>
                </div>
                <Pagination className="flex justify-center">
                    <Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
                        Anterior
                    </Button>
                    {renderPageNumbers()}
                    <Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
                        Siguiente
                    </Button>
                </Pagination>
            </div>

            <Modal open={open} ref={detailModal}>
                <form method="dialog">
                    <Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }} onClick={cerrarModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                    </Button>
                </form>
                <Modal.Header className="font-bold">{nombre}</Modal.Header>
                <Modal.Body>
                    {!saveLoading ? <Loading color='primary' /> : <>
                        <form onSubmit={handleSubmit(guardarCuestionario)}>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Imágen</span>
                                </label>
                                <FileInput className="input input-bordered" onChange={handleFileChange} /* value={imagen} */ accept="image/*" style={{ padding: '0' }} />
                                <small style={{ marginTop: '5px' }}>Medida sugerida: 1920 x 350px</small>
                            </div>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Nombre</span>
                                </label>
                                <Input
                                    color={errors?.nombre ? 'error' : 'neutral'}
                                    type="text"
                                    placeholder="Nombre"
                                    id="nombre"
                                    {...register('nombre')}
                                    onChange={(e) => setNombre(e.target.value)}
                                    value={nombre}
                                    
                                />
                                {errors.nombre && <span className="label-text-alt text-error">{errors.nombre.message}</span>}
                            </div>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Descripción</span>
                                </label>
                                <Textarea color={errors?.descripcion ? 'error' : 'neutral'} {...register('descripcion')} placeholder="Descripción" className="input input-bordered" style={{ minHeight: '100px' }} onChange={(e) => setDescripcion(e.target.value)} value={descripcion} />
                                {errors.descripcion && <span className="label-text-alt text-error">{errors.descripcion.message}</span>}
                            </div>
                            <div className={['form-control mb-2', styles.checkbox].join(' ')}>
                                <Checkbox color="primary" checked={activo} onChange={(e) => setActivo(e.target.checked)} id="activo" />
                                <label className="label" htmlFor="activo">
                                    <span className="label-text" style={{ fontSize: '18px' }}>Activo</span>
                                </label>
                            </div>
                        </form>
                    </>}
                </Modal.Body>
                <Modal.Actions>
                    <Button color="primary" fullWidth onClick={handleSubmit(guardarCuestionario)}>Guardar</Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}
