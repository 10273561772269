import axios from "axios";
import { useEffect, useState } from "react";
import { Alert, Badge, Button, Checkbox, Loading, Rating } from "react-daisyui";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Preview.module.scss";

export default function Preview() {
    const [preview, setPreview] = useState<any>(null);
    const [showAll, setShowAll] = useState(false);
    const { cuestionarioId } = useParams()
    const navigate = useNavigate();

    const descargarInfoPreview = () => {
        axios.get(`/api/cuestionarios/${cuestionarioId}/preview`)
            .then((response) => {
                setPreview(response.data.cuestionario);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    const renderParrafos = (text: string) => {
        return text.split('\n').map((str, index) => (
            <p className="mb-8 text-xl" key={index}>
                {str}
            </p>
        ));
    };

    useEffect(() => {
        descargarInfoPreview();
    }, [])


    return (
        <div className={styles.preview_wrapper}>
            <div className={styles.actions}>
                <Button onClick={() => navigate(-1)} color="ghost" size='sm'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z" /></svg>
                    Volver
                </Button>

                <div className={['form-control mb-2', styles.checkbox].join(' ')}>
                    <Checkbox color="primary" checked={showAll} onChange={(e) => setShowAll(e.target.checked)} id="temas" />
                    <label className="label" htmlFor="temas">
                        <span className="label-text" style={{ fontSize: '18px' }}>Mostrar todos los temas</span>
                    </label>
                </div>
            </div>
            {preview ? (
                <div className={styles.preview}>
                    {showAll &&
                        <Alert className="mb-8" status="info" icon={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill=""><path d="M480-280q17 0 28.5-11.5T520-320v-160q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480v160q0 17 11.5 28.5T480-280Zm0-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>}>
                            <span>Los temas inactivos no serán mostrados en el cuestionario</span>
                        </Alert>
                    }

                    <img src={`/api/cuestionarios/imagen/${preview.imagen}`} className={styles.imagen} alt={preview.nombre} />
                    <h1 className={styles.nombre}>{preview.nombre}</h1>
                    <p>{renderParrafos(preview.descripcion)}</p>

                    {preview.temas
                        .filter((tema: any) => (showAll ? true : tema.tema_activo))
                        .map((tema: any) => (
                            <div key={tema.id} className={[styles.tema, tema.tema_activo ? styles.activo : styles.inactivo].join(' ')}>
                                <h2>{tema.nombre_mostrable}</h2>
                                <div className={styles.datos}>
                                    <p>Orden: {tema.orden}</p>
                                    <p>Peso: <Rating value={tema.peso || 0} color="warning" size="sm" className="items-center">
                                        <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                        <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                        <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                        <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                        <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                    </Rating>
                                    </p>
                                </div>

                                <div className={styles.preguntas}>
                                    <ul className={styles.pregunta}>
                                        {tema.preguntas.map((pregunta: any) => (
                                            <li key={pregunta.id_pregunta} style={{ marginLeft: pregunta.sub_orden > 0 ? '2.5rem' : '0px' }}>
                                                <span className={styles.numero}>{pregunta.orden}.{pregunta.sub_orden}</span>
                                                <span>
                                                    {pregunta.texto_pregunta}
                                                    {pregunta.informativa && <Badge color="info" style={{ marginLeft: '0.5rem' }}>Informativa</Badge>}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                </div>
            ) : (
                <div className={styles.loading}>
                    <Loading color="primary" />
                </div>

            )}
        </div>
    )
}