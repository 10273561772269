import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Cliente, Empresa } from "./interface";
import Swal from "sweetalert2";
import { Button, Input, Loading, Modal, Pagination, Select, Table, Tooltip } from "react-daisyui";
import { useDebouncedCallback } from "use-debounce";
import styles from "./EmpresasDetalle.module.scss";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

type ClientesSchemaType = Yup.InferType<typeof clientesSchema>;

const clientesSchema = Yup.object().shape({
    nombre: Yup.string()
        .required('Campo requerido')
        .min(2, 'Mínimo 2 caracteres'),
    apellido: Yup.string()
        .required('Campo requerido')
        .min(2, 'Mínimo 2 caracteres'),
    email: Yup.string()
        .required('Campo requerido')
})

export default function EmpresasDetalle() {
    const [open, setOpen] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const { empresaId } = useParams();
    const [empresa, setEmpresa] = useState<Empresa | null>(null);
    const [cliente, setCliente] = useState<Cliente | null>(null);
    const [clientes, setClientes] = useState<Array<Cliente>>([]);
    const [errorPassword, setErrorPassword] = useState<string>('');

    const [nombre, setNombre] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [apellido, setApellido] = useState<string>('');
    const [cargo, setCargo] = useState<string>('');
    const [telefono, setTelefono] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [search, setSearch] = useState<string>('');
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)

    const detailModal = useRef<HTMLDialogElement>(null);
    const detailModalPassword = useRef<HTMLDialogElement>(null);
    const navigate = useNavigate();

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<ClientesSchemaType>({
        resolver: yupResolver(clientesSchema)
    });

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;
        const totalPages = Math.ceil(total / limit);
        const maxPage = Math.min(totalPages, maxPageButtons);
        const middlePage = Math.ceil(maxPage / 2);
        const startPage = Math.max(1, page - middlePage);
        const endPage = Math.min(totalPages, startPage + maxPage - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
                    {i}
                </Button>
            );
        }

        return pageNumbers;
    }

    const descargarInfoEmpresa = () => {
        if (empresaId) {
            axios.get(`/api/empresas/${empresaId}`)
                .then((response) => {
                    setEmpresa(response.data.empresa);
                })
                .catch((error) => {
                    console.error("Error al cargar los datos de la empresa:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const descargarClientes = () => {
        if (empresaId) {
            axios.get(`/api/empresas/${empresaId}/clientes`)
                .then((response) => {
                    setClientes(response.data.data);
                    setTotal(response.data.total);
                })
                .catch((error) => {
                    console.error("Error al cargar los clientes del cliente:", error);
                });
        }
    }

    const guardarCliente = () => {
        axios(`/api/empresas/${empresaId}/clientes${cliente && cliente.id ? `/${cliente.id}` : ''}`, {
            method: cliente && cliente.id ? 'PUT' : 'POST',
            data: {
                ...cliente,
                nombre,
                apellido,
                email,
                cargo,
                telefono
            }
        }).then(() => {
            Swal.fire({
                heightAuto: false,
                icon: 'success',
                title: '¡Listo!',
                text: 'El cliente se ha guardado correctamente'
            });
        }).catch((error) =>
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: 'Error al guardar el cliente',
                text: error.response.data.message
            }))
            .finally(() => {
                setSaveLoading(false);

                detailModal.current?.close();
                setOpen(false);
                setCliente(null);
                setNombre('');
                setApellido('');
                setEmail('');
                setCargo('');
                setTelefono('');
                descargarClientes();
            });
    }

    const editarCliente = (c: Cliente) => {
        setCliente(c);
        detailModal.current?.showModal();
        setNombre(c.nombre || '');
        setValue('nombre', c.nombre || '');
        setApellido(c.apellido || '');
        setValue('apellido', c.apellido || '');
        setEmail(c.email || '');
        setValue('email', c.email || '');
        setCargo(c.cargo || '');
        setTelefono(c.telefono || '');
    }

    const eliminarCliente = (c: Cliente) => {
        Swal.fire({
            heightAuto: false,
            icon: 'warning',
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará el cliente de forma permanente.',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/api/empresas/${empresaId}/clientes/${c.id}`).then(() => {
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        title: '¡Listo!',
                        text: 'El cliente se ha eliminado correctamente'
                    });
                }).catch((error) =>
                    Swal.fire({
                        heightAuto: false,
                        icon: 'error',
                        title: 'Error al eliminar el cliente',
                        text: error.response.data.message
                    })
                )
                    .finally(() => {
                        descargarClientes();
                    });
            }
        })
    }

    const resetPassword = (c: Cliente) => {
        setCliente(c);
        detailModalPassword.current?.showModal();
        setNombre(c.nombre || '');
        setValue('nombre', c.nombre || '');
        setApellido(c.apellido || '');
        setValue('apellido', c.apellido || '');
        setEmail(c.email || '');
        setValue('email', c.email || '');
        setCargo(c.cargo || '');
        setTelefono(c.telefono || '');
    }

    const forzarPassword = () => {
        if (!password.trim()) {
            setErrorPassword('La contraseña es requerida.');
            return;
        }

        if (password.length < 4) {
            setErrorPassword('La contraseña debe tener al menos 4 caracteres.');
            return;
        }
        setErrorPassword('');

        axios(`/api/empresas/${empresaId}/clientes/${cliente?.id}/reset-password`, {
            method: 'PUT',
            data: {
                password
            }
        }).then(() => {
            Swal.fire({
                heightAuto: false,
                icon: 'success',
                title: '¡Listo!',
                text: 'Se ah resetado el password del cliente'
            });
        }).catch((error) => 
            Swal.fire({
                target: document.querySelector<HTMLElement>('#modal-password'),
                heightAuto: false,
                icon: 'error',
                title: 'Error al intentar resetear el password',
                text: error.response.data.message
            }))
            .finally(() => {
                setSaveLoading(false);

                detailModalPassword.current?.close();
                setOpenPassword(false);
                setPassword('');
                setOpen(false);
                setCliente(null);
                setNombre('');
                setApellido('');
                setEmail('');
                setCargo('');
                setTelefono('');

            });
    }

    const cerrarModal = () => {
        setApellido('')
        setNombre('')
        setEmail('')
        setCargo('')
        setTelefono('')
        setOpen(false);
        detailModal.current?.close();
    }

    const cerrarModalPassword = () => {
        setApellido('')
        setNombre('')
        setEmail('')
        setCargo('')
        setTelefono('')
        setOpenPassword(false);
        detailModal.current?.close();
    }

    useEffect(() => {
        reset();
        descargarInfoEmpresa();
        descargarClientes();
    }, [empresaId, limit, page, search, open]);

    const debounced = useDebouncedCallback((value) => {
        setSearch(value);
    }, 500);

    return (
        <div className={styles.clientes_wrapper}>
            <h3 className={styles.title}>Clientes de la empresa {empresa?.nombre || 'Cargando...'}</h3>
            <div className={styles.actions}>
                <Button onClick={() => navigate(-1)} color="ghost" size='sm'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z" /></svg>
                </Button>
                <Input className='w-full' placeholder="Buscar" size="sm" onChange={(e) => debounced(e.target.value)} />
                <Button color="primary" size='sm' onClick={() => setOpen(true)}>Agregar cliente</Button>
            </div>
            <div className="h-full" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <div className="scrollbar-none">
                    {loading ? <div className={styles.loading}><Loading color="primary" /></div> :
                        <Table zebra pinRows>
                            <Table.Head className="th_acciones">
                                <span>Apellido</span>
                                <span>Nombre</span>
                                <span>Email</span>
                                <span>Cargo</span>
                                <span>Teléfono</span>
                                <span>Acciones</span>
                            </Table.Head>

                            <Table.Body>
                                {clientes.map((c, item) => (
                                    <Table.Row key={item} hover>
                                        <span>{c.apellido}</span>
                                        <span>{c.nombre}</span>
                                        <span>{c.email}</span>
                                        <span>{c.cargo}</span>
                                        <span>{c.telefono}</span>

                                        <span>
                                            <div className="flex space-x-2 items-center gap-2">
                                                <Tooltip message="Editar">
                                                    <Button size="sm" shape="square" color="secondary" onClick={() => editarCliente(c)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"><path d="M200-200h57l391-391-57-57-391 391v57Zm-40 80q-17 0-28.5-11.5T120-160v-97q0-16 6-30.5t17-25.5l505-504q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L313-143q-11 11-25.5 17t-30.5 6h-97Zm600-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                                    </Button>
                                                </Tooltip>

                                                <Tooltip message="Eliminar">
                                                    <Button size="sm" shape="square" color="error" onClick={() => eliminarCliente(c)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM400-280q17 0 28.5-11.5T440-320v-280q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v280q0 17 11.5 28.5T400-280Zm160 0q17 0 28.5-11.5T600-320v-280q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v280q0 17 11.5 28.5T560-280ZM280-720v520-520Z" /></svg>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip message="Forzar Password">
                                                    <Button size="sm" shape="square" color="primary" onClick={() => resetPassword(c)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80q-76 0-144.5-27t-122-74.5Q160-229 126-293.5T83-434q-2-19 9-29.5t26-12.5q15-2 29 6.5t16 29.5q15 121 105.5 201T480-159q134 0 227-93t93-227q0-134-93-227t-227-93q-89 0-161.5 43.5T204-640h76q17 0 28.5 11.5T320-600q0 17-11.5 28.5T280-560H120q-17 0-28.5-11.5T80-600v-160q0-17 11.5-28.5T120-800q17 0 28.5 11.5T160-760v40q55-73 138-116.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480ZM400-320q-17 0-28.5-11.5T360-360v-120q0-17 11.5-28.5T400-520v-40q0-33 23.5-56.5T480-640q33 0 56.5 23.5T560-560v40q17 0 28.5 11.5T600-480v120q0 17-11.5 28.5T560-320H400Zm40-200h80v-40q0-17-11.5-28.5T480-600q-17 0-28.5 11.5T440-560v40Z" /></svg>
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </span>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    }
                </div>
            </div>

            <div className={styles.pagination}>
                <div className={styles.cantidad}>
                    <small >Cantidad por página:</small>
                    <Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </Select>
                </div>
                <Pagination className="flex justify-center">
                    <Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
                        Anterior
                    </Button>
                    {renderPageNumbers()}
                    <Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
                        Siguiente
                    </Button>
                </Pagination>
            </div>
            <Modal open={open} ref={detailModal}>
                <form method="dialog">
                    <Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }} onClick={cerrarModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                    </Button>
                </form>
                <Modal.Header className="font-bold">{nombre} {apellido}</Modal.Header>
                <Modal.Body>
                    {saveLoading ? <Loading color='primary' /> : <>
                        <form onSubmit={handleSubmit(guardarCliente)}>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Apellido</span>
                                </label>
                                <Input
                                    type="text"
                                    color={errors.apellido ? 'error' : 'neutral'}
                                    placeholder="Apellido"
                                    {...register('apellido')}
                                    onChange={(e) => setApellido(e.target.value)}
                                    value={apellido}
                                />
                                {errors.apellido && <span className="label-text-alt text-error">{errors.apellido.message}</span>}
                            </div>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Nombre</span>
                                </label>
                                <Input
                                    type="text"
                                    color={errors.nombre ? 'error' : 'neutral'}
                                    {...register('nombre')}
                                    placeholder="Nombre"
                                    onChange={(e) => setNombre(e.target.value)}
                                    value={nombre}
                                />
                                {errors.nombre && <span className="label-text-alt text-error">{errors.nombre.message}</span>}
                            </div>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Email</span>
                                </label>
                                <Input
                                    type="email"
                                    color={errors.email ? 'error' : 'neutral'}
                                    {...register('email')}
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                                {errors.email && <span className="label-text-alt text-error">{errors.email.message}</span>}
                            </div>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Cargo</span>
                                </label>
                                <Input type="text" placeholder="Cargo" onChange={(e) => setCargo(e.target.value)} value={cargo} />
                            </div>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Teléfono</span>
                                </label>
                                <Input type="number" placeholder="Telefono" onChange={(e) => setTelefono(e.target.value)} value={telefono} />
                            </div>
                        </form>
                    </>}
                </Modal.Body>
                <Modal.Actions>
                    <Button color="primary" fullWidth onClick={handleSubmit(guardarCliente)}>Guardar</Button>
                </Modal.Actions>
            </Modal>

            <Modal open={openPassword} ref={detailModalPassword} id="modal-password">
                <form method="dialog">
                    <Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }} onClick={cerrarModalPassword}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                    </Button>
                </form>
                <Modal.Header className="font-bold">{nombre} {apellido}</Modal.Header>
                <Modal.Body>
                    {saveLoading ? <Loading color='primary' /> : <>
                        <form onSubmit={handleSubmit(() => forzarPassword())}>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Contraseña</span>
                                </label>
                                <Input
                                    type="password"
                                    color={errorPassword ? 'error' : 'neutral'}
                                    placeholder="Contraseña"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setErrorPassword('');
                                    }}
                                    value={password}
                                />
                                {errorPassword && <span className="label-text-alt text-error">{errorPassword}</span>}
                            </div>
                        </form>
                    </>}
                </Modal.Body>
                <Modal.Actions>
                    <Button color="primary" fullWidth onClick={handleSubmit(() => forzarPassword())}>Guardar</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}
