import { createBrowserRouter, Outlet, RouterProvider, useRouteError } from "react-router-dom";
import App from "../App";
import Dashboard from "../pages/dashboard/Dashboard";
import Empresas from "../pages/empresas/Empresas";
import Cuestionarios from "../pages/cuestionarios/Cuestionarios";
import Encuestas from "../pages/encuestas/Encuestas";
import EmpresasDetalle from "../pages/empresas/EmpresasDetalle";
import Temas from "../pages/cuestionarios/temas/Temas";
import Preguntas from "../pages/cuestionarios/preguntas/Preguntas";
import Preview from "../pages/cuestionarios/preview/Preview";
import PreviewTema from "../pages/cuestionarios/temas/preview/Preview";
import { AuthProvider, RedirectBasedOnPermissions, RestrictedForRoles } from "../providers/AuthContext";
import Encuestado from "../pages/encuestado/Encuestado";
import EncuestaDetalle from "../pages/encuestado/EncuestaDetalle";
import Resultados from "../pages/encuestas/resultados/Resultados";
import ResultadoEmpresa from "../pages/empresas/ResultadoEmpresa";
const Error = () => {
	const error: any = useRouteError()

	return (
		<App>
			<div>{error?.message ?? 'Error desconocido'}</div>
		</App>
	)
}

const MainLayout = () => {
	return (
		<AuthProvider>
			<App>
				<Outlet />
			</App>
		</AuthProvider>
	)
}

const router = createBrowserRouter([
	{
		path: "/",
		element: <MainLayout />,
		children: [
			{
				path: `/`,
				index: true,
				element:  <RedirectBasedOnPermissions/>, //<Navigate to="/empresas" replace />
			},
			{
				path: `/dashboard`,
				element: (
					<RestrictedForRoles roles={["admin"]} component={<Dashboard />} />
				),
			},
			{
				path: `/empresas`,
				children: [
					{index: true, element: (<RestrictedForRoles roles={["admin"]} component={<Empresas />} />)},
					{path: `:empresaId`, element: (<RestrictedForRoles roles={["admin"]} component={<EmpresasDetalle />} />)},
					{path: `:empresaId/resultados`, element: (<RestrictedForRoles roles={["admin"]} component={<ResultadoEmpresa />} />)},
				]
			},
			{
				path: `/cuestionarios`,
				children: [
					{index: true, element: (<RestrictedForRoles roles={["admin"]} component={<Cuestionarios />} />)}, //<Cuestionarios />},
					{path: `:cuestionarioId`, element: (<RestrictedForRoles roles={["admin"]} component={<Temas />} />)}, //<Temas />},
					{path: `:cuestionarioId/preview`, element: (<RestrictedForRoles roles={["admin"]} component={<Preview />} />)}, //<Preview />},
					{path: `:cuestionarioId/temas/:temaId/preguntas`, element: (<RestrictedForRoles roles={["admin"]} component={<Preguntas />} />)}, //<Preguntas />},
					{path: `:cuestionarioId/temas/:temaId/preview`, element: (<RestrictedForRoles roles={["admin"]} component={<PreviewTema />} />)}, //<PreviewTema />},
				]
			},
			
			{
				path: `/encuestas`,
				children: [
					{index: true, element: (<RestrictedForRoles roles={["admin"]} component={<Encuestas />} />)}, //<Encuestas />}
					{path: `:clienteId/:cuestionarioId/resultados`, element: (<RestrictedForRoles roles={["admin"]} component={<Resultados />} />)},
				]
			},
			{
				path: `/encuesta/cliente`,
				children: [
					{index: true, element: (<RestrictedForRoles roles={["encuestado"]} component={<Encuestado />} />)}, 
					{path: `:cuestionarioId`, element: (<RestrictedForRoles roles={["encuestado"]} component={<EncuestaDetalle />} />)},
				]

			},
		],
		errorElement: <Error />,
	},
]);

function Routes() {
	return <RouterProvider router={router} />
}

export default Routes