import axios from "axios"
import { useEffect, useState } from "react"
import type { Encuestado } from "./interface"
import { useAuth } from "../../providers/AuthContext"
import styles from "./Encuestado.module.scss"
import { Avatar, Badge, Button, Input, Loading, Mask, Pagination, Select, Table, Tooltip } from "react-daisyui"
import { useDebouncedCallback } from "use-debounce"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

export default function Encuestado() {
    const [loading, setLoading] = useState<boolean>(false)
    const [encuestas, setEncuestas] = useState<Encuestado[] | null>(null);
    const [search, setSearch] = useState<string>('')
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)
    const navigate = useNavigate();

    const { user } = useAuth();

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;
        const totalPages = Math.ceil(total / limit);
        const maxPage = Math.min(totalPages, maxPageButtons);
        const middlePage = Math.ceil(maxPage / 2);
        const startPage = Math.max(1, page - middlePage);
        const endPage = Math.min(totalPages, startPage + maxPage - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
                    {i}
                </Button>
            );
        }
        return pageNumbers;
    }

    const descargarEncuesta = () => {
        setLoading(true);
        axios.get(`/api/encuestas/${user?.id}?offset=` + (page - 1) * limit + "&limit=" + limit + (search ? "&filters[search]=" + search : '')).then((response) => {
            setEncuestas(response.data.data);
            setTotal(response.data.total);
        }).finally(() => {
            setLoading(false);
        })
    }

    const iniciarEncuesta = (id_cuestionario_cliente: string, id_cliente: string) => {
        axios(`/api/encuestas/${id_cliente}/${id_cuestionario_cliente}/iniciar`, {
            method: 'PUT',
            data: {}
        }).then(() => {
            Swal.fire({
                heightAuto: false,
                icon: 'success',
                title: 'Encuesta iniciada',
                text: 'Encuesta iniciada con exito',
            })
        }).catch((error) => {
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: 'Error al iniciar la encuesta',
                text: error.response.data.message
            })
        }).finally(() => {
            descargarEncuesta();
            setLoading(false);
        })
    }

    const detalleEncuesta = (encuesta: Encuestado) => {
        navigate(`/encuesta/cliente/${encuesta.id_cuestionario_cliente}`)
    }

    useEffect(() => {
        descargarEncuesta();
    }, [page, limit, search])

    const debounced = useDebouncedCallback((value) => {
        setSearch(value);
    }, 500);

    const getBadgeColor = (value: number) => {
        if (value >= 0 && value <= 50) return 'error';
        if (value > 50 && value <= 85) return 'warning';
        if (value > 85) return 'success';
        return 'secondary';
    }

    const badgeColorEstado = (estado: number) => {
        const estados: { [key: number]: JSX.Element } = {
            1: <Badge color="warning">Pendiente de responder</Badge>,
            2: <Badge color="primary">Iniciado</Badge>,
            3: <Badge color="success">Entregado</Badge>,
        };
    
        return estados[estado] || '';
    };

    return (
        <div className={styles.encuestas_wrapper}>
            <div className={styles.actions}>
                <Input className='w-full' placeholder="Buscar por nombre" size="sm" onChange={(e) => debounced(e.target.value)} />
            </div>
            <div className="h-full" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <div className="scrollbar-none">
                    {loading ? <div className={styles.loading}><Loading color="primary" /></div> :
                        <Table zebra pinRows>
                            <Table.Head className="th_acciones">
                                <span>Imágen</span>
                                <span>Nombre</span>
                                <span>Descripción</span>
                                <span>Estado</span>
                                <span>Resultado</span>
                                <span>Acciones</span>
                            </Table.Head>

                            <Table.Body>
                                {encuestas && encuestas.map((encuesta, item) => (
                                    <Table.Row key={item} hover>
                                        <span>
                                            {encuesta.imagen &&
                                                <Avatar size={'sm'} src={`/api/cuestionarios/imagen/${encuesta.imagen}`} innerClassName={Mask.className({
                                                    variant: 'squircle'
                                                })} />
                                            }
                                        </span>
                                        <span>{encuesta.nombre}</span>
                                        <span className="line-clamp">{encuesta.descripcion}</span>
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {badgeColorEstado(Number(encuesta.id_estado))}
                                        </span>
                                        <span>
                                            {encuesta.resultado != null ? (
                                                <Badge color={getBadgeColor(encuesta.resultado || 0)}>
                                                    {Number(encuesta.resultado).toFixed(2)}%
                                                </Badge>
                                            ) : <></>}
                                        </span>
                                        <span>
                                            <div className="flex space-x-2 items-center gap-2">
                                                <Tooltip message="Preview">
                                                    <Button size="sm" shape="square" color="primary" onClick={() => detalleEncuesta(encuesta)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-134 0-244.5-72T61-462q-5-9-7.5-18.5T51-500q0-10 2.5-19.5T61-538q64-118 174.5-190T480-800q134 0 244.5 72T899-538q5 9 7.5 18.5T909-500q0 10-2.5 19.5T899-462q-64 118-174.5 190T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>
                                                    </Button>
                                                </Tooltip>
                                                {Number(encuesta.id_estado) == 1 &&
                                                    <Tooltip message="Comenzar">
                                                        <Button size="sm" shape="square" color="success" onClick={() => iniciarEncuesta(encuesta.id_cuestionario_cliente ?? '', encuesta.id_cliente ?? '')}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M320-273v-414q0-17 12-28.5t28-11.5q5 0 10.5 1.5T381-721l326 207q9 6 13.5 15t4.5 19q0 10-4.5 19T707-446L381-239q-5 3-10.5 4.5T360-233q-16 0-28-11.5T320-273Zm80-207Zm0 134 210-134-210-134v268Z" /></svg>
                                                        </Button>
                                                    </Tooltip>
                                                }
                                            </div>
                                        </span>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    }
                </div>
            </div>
            <div className={styles.pagination}>
                <div className={styles.cantidad}>
                    <small>Cantidad por página:</small>
                    <Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </Select>
                </div>
                <Pagination className="flex justify-center">
                    <Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
                        Anterior
                    </Button>
                    {renderPageNumbers()}
                    <Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
                        Siguiente
                    </Button>
                </Pagination>
            </div>
        </div>
    )
}