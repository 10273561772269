export type Encuestado = {
    id_cuestionario_cliente?: string;
    id_cliente?: string;
    id_cuestionario?: string;
    id_empresa?: string;
    nombre?: string;
    descripcion?: string;
    imagen?: string;
    resultado?: number;
    id_estado?: string;
    estado?: string;
    
}

export class Tema {
    id_tema?: string;
    nombre?: string | null;
    nombre_mostrable?: string | null;
    detalle?: string | null;
    informativo?: boolean | null;
    tema_activo?: boolean | null;
    id_cuestionario_tema?: string;
    id_cuestionario?: string | null;
    peso?: number | null;
    orden?: number | null;
    cuestionario_tema_activo?: boolean | null;
    preguntas?: Array<Pregunta> | null;
    resultado_tema?: number;
  }

export class Pregunta {
    id_pregunta?: string;
    texto_pregunta?: string | null;
    detalle?: string | null;
    informativa?: boolean | null;
    respuesta_correcta?: boolean | null;
    pregunta_activo?: boolean | null;
    id_pregunta_tema?: string;
    id_tema?: string | null;
    peso?: number | null;
    orden?: number | null;
    sub_orden?: number | null;
    no_aplica?: boolean;
    respuesta_libre?: string;
    peso_obtenido?: number;
    respuesta_usuario?: boolean;
    id?: string;
    subpreguntas?: Array<Pregunta> | null;
  }

export class Respuesta {
  respuesta_usuario?: boolean;
  respuesta_libre?: string;
  no_aplica?: boolean;
}